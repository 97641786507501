import { getServiceStore } from '@/plugins/FeathersAPI';

// import { isReactive, isReadonly, isProxy, isRef } from 'vue';

export default {
	servicePath: 'profiles',
	modelName: 'Profile',
	setupInstance(data, { models, defineSingleAssociation, defineManyAssociation }) {
		data.sortableName = data.fullName ? data.fullName.toLowerCase() : '';
		data.sortablePatientName = data.patientFullName ? data.patientFullName.toLowerCase() : '';

		defineSingleAssociation(data, 'user', models.api.User);
		defineSingleAssociation(data, 'latestInClinicSession', models.api.ActivitySession);
		defineSingleAssociation(data, 'latestSessionByActivityId', models.api.ActivitySession);
		defineManyAssociation(data, 'patients', models.api.Profile);
		defineManyAssociation(data, 'clinicians', models.api.Profile);
		defineManyAssociation(data, 'treatmentPlan', models.api.Activity);
		defineManyAssociation(data, 'resourcePlan', models.api.Resource);
		defineManyAssociation(data, 'homeworkPlan', models.api.Homework);

		return data;
	},
	state: {
		id: undefined
	},
	getters: {
		profile() {
			return this.id ? this.getFromStore(this.id) : {};
		},
		isAuthedAsPatient() {
			return getServiceStore('auth').user.fullPermissionsList.includes('patient');
		},
		patientReportsDisplayName() {
			// if a patient is viewing a report, they see their own name
			// if a clinician is viewing a report, they see the name they assigned to the patient
			if (this.isAuthedAsPatient) {
				return this.profile.firstName;
			} else {
				if (!this.profile.patientReportsName) return this.patientReportsDefaultDisplayName();
				let name = this.profile.patientReportsName
					.replace(/\[firstName\]/g, this.profile.patientFirstName)
					.replace(/\[lastName\]/g, this.profile.patientLastName);
				return name;
			}
		},
		pronounSubject() {
			if (!this.profile.patientPronouns) return this.patientReportsDisplayName;
			const pronouns = this.profile.patientPronouns.split('/');
			return pronouns[0];
		},
		shouldCorrectCaseOnPatientReportsDisplayName() {
			if (this.isAuthedAsPatient) {
				// leave the name as the patient entered it
				return false;
			}
			if (!this.profile.patientReportsName) {
				// default 'Patient' should change case
				return true;
			}
			const name = this.profile.patientReportsName.toLowerCase();
			if (name === 'patient' || name === 'client' || name === 'resident') {
				return true;
			}
			return false;
		},
		shouldCorrectCaseOnPatientReportsPronouns() {
			if (this.profile.patientPronouns) {
				return true;
			}
			return this.shouldCorrectCaseOnPatientReportsDisplayName;
		}
	},
	actions: {
		patientReportsDefaultDisplayName() {
			return 'Patient';
		}
	},
	hooks: {
		before: {
			find: [
				(context) => {
					if (context.params && context.params.query && context.params.query.$sort) {
						const $sort = context.params.query.$sort;
						if (Object.keys($sort).includes('sortableName')) {
							$sort.firstName = $sort.sortableName;
							$sort.lastName = $sort.sortableName;
							delete $sort.sortableName;
						}
						if (Object.keys($sort).includes('sortablePatientName')) {
							$sort.patientFirstName = $sort.sortablePatientName;
							$sort.patientLastName = $sort.sortablePatientName;
							delete $sort.sortablePatientName;
						}
						context.params.query.$sort = $sort;
					}
					return context;
				}
			]
		}
	}
};
