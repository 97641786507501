import users from './users';
import sessions from './sessions';
import roles from './roles';
import permissions from './permissions';
import licenseTypes from './license-types';
import profiles from './profiles';
import words from './words';
import definitions from './definitions';
import images from './images';
import semanticGroups from './semantic-groups';
import categories from './categories';
import audio from './audio';
import letters from './letters';
import exercises from './exercises';
import exerciseInstructions from './exercise-instructions';
import exerciseGenerator from './exercise-generator';
import activitySessions from './activity-sessions';
import levels from './levels';
import tags from './tags';
import activities from './activities';
import sounds from './sounds';
import startingSoundCues from './starting-sound-cues';
import talking1000Words from './talking-1000-words';
import directions from './directions';
import streams from './streams';
import status from './status';
import videos from './videos';
import problems from './problems';
import solutions from './solutions';
import authManagement from './auth-management';
import externalData from './external-data';
import shutterstockImages from './shutterstock-images';
import goals from './goals';
import goalLongTermGoals from './goal-long-term-goals';
import goalShortTermGoals from './goal-short-term-goals';
import goalLongTermShortTermGoals from './goal-long-term-short-term-goals';
import goalDynamicStgGoals from './goal-dynamic-stg-goals';
import goalVariables from './goal-variables';
import goalDynamicVariableGoals from './goal-dynamic-variable-goals';
import goalGoalDynamicStgGoals from './goal-goal-dynamic-stg-goals';
import goalGoalDynamicVariableGoals from './goal-goal-dynamic-variable-goals';
import flows from './flows';
import pdfReports from './pdf-reports';
import encrypt from './encrypt';
import fastspring from './fastspring';
import licenses from './licenses';
import recommendations from './recommendations';
import notifications from './notifications';
import userNotifications from './user-notifications';
import homework from './homework';
import stats from './stats';
import maintenance from './maintenance';
import convertkit from './convertkit';
import performanceMeasurements from './performance-measurements';
import speechRecognition from './speech-recognition';
import errorRecordings from './error-recordings';
import numbers from './numbers';
import toolCategories from './tool-categories';
import tools from './tools';
import deckCards from './deck-cards';
import socket from './socket';
import resources from './resources';
import pdfs from './pdfs';

const services = [
	users,
	sessions,
	roles,
	permissions,
	licenseTypes,
	profiles,
	words,
	definitions,
	images,
	semanticGroups,
	categories,
	audio,
	letters,
	exercises,
	exerciseInstructions,
	exerciseGenerator,
	activitySessions,
	levels,
	tags,
	activities,
	sounds,
	startingSoundCues,
	talking1000Words,
	directions,
	streams,
	status,
	videos,
	problems,
	solutions,
	authManagement,
	externalData,
	shutterstockImages,
	goals,
	goalLongTermGoals,
	goalShortTermGoals,
	goalLongTermShortTermGoals,
	goalDynamicStgGoals,
	goalVariables,
	goalDynamicVariableGoals,
	goalGoalDynamicStgGoals,
	goalGoalDynamicVariableGoals,
	flows,
	pdfReports,
	encrypt,
	fastspring,
	licenses,
	recommendations,
	notifications,
	userNotifications,
	homework,
	stats,
	maintenance,
	convertkit,
	performanceMeasurements,
	speechRecognition,
	errorRecordings,
	numbers,
	socket,
	resources,
	pdfs,
	toolCategories,
	tools,
	deckCards
];

export default services;
